<template>
  <div class="row">
    <div class="flex xs12">
      <data-form
        :resource="resource"
        :loading="loading"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script>
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'resources-new',
  components: {
    DataForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      headers: { 'Content-Type': 'multipart/form-data' },
      loading: false,
      resource: { delete: false },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'resources/'
    },
    async submit (resource) {
      this.loading = true

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), resource)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })

      if (this.modal) {
        this.$emit('update')
        return
      }
      this.$router.push({ name: 'resourcesEdit', params: { id: response.data.data.id } })
    },
  },
}
</script>
